<template>
  <Head>
    <title>
      Детская инфраструктура — собственный детский сад {{ $titleEnd }}
    </title>
    <meta
      name="description"
      content="Развивающие игры, первые победы, новые знакомства и крепкая дружба. Все это ждет ваших детей во дворе уникальных впечатлений. 2 500 кв. м детских площадок. Собственный детский сад на территории квартала."
    />
    <meta
      property="og:title"
      :content="'Детская инфраструктура — собственный детский сад ' + $titleEnd"
    />
    <meta
      property="og:description"
      content="Развивающие игры, первые победы, новые знакомства и крепкая дружба. Все это ждет ваших детей во дворе уникальных впечатлений. 2 500 кв. м детских площадок. Собственный детский сад на территории квартала."
    />
    <meta
      property="og:image"
      content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/GcaaogIqfHh8nj9abjg0ItrUnGJziLHHyMvopSpY.jpg"
    />
  </Head>
  <main class="main my-0">
    <Hero
      :content="hero"
      :breadcrumbs="[
        { name: 'Home', text: 'Главная' },
        { name: 'Advantages', text: 'Преимущества' },
        { text: hero.title },
      ]"
    />
    <Basis :content="basis" />
    <Image :content="image" />
    <Gallery :content="gallery" />
    <Green class="oqzlspakmhtnmeqf" :content="green" />
    <Others :current="$options.name" />
    <HomeChoose />
  </main>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Basis from "@/components/adv/inner/Basis.vue";
import Image from "@/components/adv/inner/Image.vue";
import Gallery from "@/components/adv/inner/Gallery.vue";
import Green from "@/components/adv/inner/Green.vue";
import Others from "@/components/adv/inner/Others.vue";
import HomeChoose from "@/components/home/Choose.vue";
import { Head } from "@vueuse/head";
export default {
  name: "Kids",
  components: {
    Head,
    Hero,
    Basis,
    Image,
    Gallery,
    Green,
    Others,
    HomeChoose,
  },
  data() {
    return {
      hero: {
        title: "VERY детский",
        text: "<p>Развивающие игры, первые победы, новые знакомства и&nbsp;крепкая дружба.<br /> Все это ждет ваших детей во&nbsp;дворе уникальных впечатлений.</p>",
        background: require("@i/html/kids/kids-hero.jpg"),
        video: require("@i/html/kids/kids.mp4"),
        more: "Узнайте больше",
      },

      basis: {
        h2: "Детская инфраструктура в&nbsp;ЖК&nbsp;Very",
        title:
          '<span class="text-masked">Очень спокойно</span> за&nbsp;своих детей',
        subtitle: "2&nbsp;500&nbsp;кв.&nbsp;м<br> детских площадок",
        text: "<p>Формируем новые ощущения и&nbsp;учимся взаимопониманию, взаимодействуем с&nbsp;природой и&nbsp;играем под дождём, не&nbsp;промокая.</p>",
        list: [
          {
            title: "Двор без машин",
            text: "<p>Спокойно отпускайте детей гулять. На&nbsp;каждой из&nbsp;5&nbsp;площадок они будут в&nbsp;безопасности&nbsp;&mdash; во&nbsp;дворе-парке VERY нет машин.</p>",
            img: require("@i/html/parking/parking-m-3.jpg"),
          },
          {
            title: "Меньше риска и&nbsp;травм",
            text: "<p>Как играть и&nbsp;лазать без падений? Все площадки и&nbsp;игровые зоны оснащены специальным антитравматическим покрытием.</p>",
            img: require("@i/html/kids/kids-m-2.jpg"),
          },
          // {
          // 	title: 'Игры без посторонних',
          // 	text: '<p>Доступ на&nbsp;территорию квартала&nbsp;&mdash; по&nbsp;биометрии и&nbsp;электронным картам. Вы&nbsp;будете знать других родителей лично.</p>',
          // 	img: require('@i/html/kids/kids-m-3.jpg'),
          // },
        ],
      },

      image: {
        title: "Собственный детский сад на&nbsp;территории квартала",
        text: "<p>В&nbsp;VERY мы&nbsp;создали всё, чтобы дети сами хотели снова и&nbsp;снова встречаться в&nbsp;детском саду прямо у&nbsp;дома. Ещё 8&nbsp;детских садов и&nbsp;4&nbsp;общеобразовательных школы ждут вас в&nbsp;радиусе до&nbsp;4&nbsp;км. После занятий друзья и&nbsp;новые знакомые продолжат общаться и&nbsp;играть вместе на&nbsp;детских площадках квартала. Каждая из&nbsp;них&nbsp;&mdash; исключительная!</p>",
        img: require("@i/html/home/feats-4.jpg"),
      },

      gallery: [
        {
          title: "Игровая площадка для малышей 1-3 лет",
          img: require("@i/html/kids/kids-1.jpg"),
          text: "Максимум движения и&nbsp;безопасности для малышей. Развиваем визуальные, слуховые, тактильные ощущения, помогаем узнавать свои возможности.",
        },
        {
          title: "Самая продуманная: для малышей и&nbsp;школьников",
          img: require("@i/html/kids/kids-3.jpg"),
          text: "Как безопасно совмещать на&nbsp;общей площадке интересы детей разного возраста? Успешно выполняем невозможное!",
        },
        {
          title: "В&nbsp;дождь и&nbsp;в&nbsp;жару: площадка под тентом",
          img: require("@i/html/kids/kids-6.jpg"),
          text: "Огромная редкость для московского двора&nbsp;&mdash; возможность отдыхать с&nbsp;детьми на&nbsp;воздухе под защитой от&nbsp;дождя и&nbsp;зноя.",
        },
        {
          title: "Альпинарий - игровая площадка&nbsp;у моста",
          img: require("@i/html/kids/kids-5.jpg"),
          text: "Подъём на&nbsp;высоту, лазание, балансирование, соревнования и&nbsp;первые победы&nbsp;&mdash; на&nbsp;площадке рядом с&nbsp;башней с&nbsp;горками. Комфортную высоту спуска дети выбирают сами!",
        },
      ],

      green: {
        title: "Территория детства",
        img: require("@i/html/kids/kids-green.jpg"),
        list: [
          {
            title: "Развлечения для детей всех возрастов",
            text: "<p>Отдыхайте активно на&nbsp;ВДНХ, наслаждайтесь тишиной в&nbsp;Парке Останкино, изучайте природу в&nbsp;Ботаническом саду. Придумывайте самые различные сценарии для каждого дня!</p>",
          },
          {
            title: "77% территории двора&nbsp;&mdash; свой парк",
            text: "<p>Если нужно быть прямо рядом с&nbsp;домом, собственный ландшафтный парк во&nbsp;дворе подарит комфортную возможность отдыхать и&nbsp;играть на&nbsp;свежем воздухе, в&nbsp;окружении природы.</p>",
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.main {
  background: #f8f8f8;
}

.oqzlspakmhtnmeqf :deep(.title) {
  max-width: 630px;
}
</style>
